.subasta-nav-inf-container {
  position: fixed;
  bottom: 0;
  background-color: #00612d;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.subasta-nav-inf-icons-container {
  display: block;
  margin: 0 auto;
}
.subasta-nav-inf-icon-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0 20px;
}

.subasta-room-icon {
  margin-top: -50px;
}

.subasta-nav-sup-icons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 20%;
}
.logout {
  display: block;
  float: right;
  color: white;
  margin-right: 10px;
}
