.room-container {
  width: 85%;
  border: 2px solid white;
  border-radius: 18px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
  background-image: url("../../images/createRoomBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 40px auto;
  height: 700px;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media (max-width: 768px) {
  .room-container {
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
    background-image: url("../../images/createRoomBg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
