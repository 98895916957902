.subasta-room-container {
  min-width: 400px;
  max-width: 400px;
  margin: 30px 50px;
  background-color: #ffffff;
  border-radius: 13px;
  border-left: 14px solid #16a64a;

  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.26);
}

.subasta-room-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subasta-room-type-icon {
  padding-left: 10px;
  width: 52px;
  height: 42px;
}

.subasta-room-type-title {
  font-size: 28px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  color: #4a4a4a;
  margin-left: 20px;
}

.subasta-room-status {
  height: 28px;
  font-size: 14px;
  line-height: 28px;
  color: #4a4a4a;
  padding: 0 20px;
  border-radius: 50px;
  margin-right: 10px;
}
.subasta-room-match-name-container {
  border-bottom: 1px solid #a3ba37;
}

.subasta-room-match-name {
  font-size: 18px;
  font-weight: bold;
  color: #00612d;
  text-align: center;
  padding-bottom: 10px;
}

.subasta-room-data-container {
  padding: 10px;
}

.subasta-romm-data-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.subasta-room-data-title {
  width: 40%;
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #4a4a4a;
  font-weight: bold;
}

.subasta-room-data-field {
  font-size: 18px;
  font-weight: 300;
  color: #00612d;
}

.home-title {
  color: #00612d;
  font-size: 1.3em;
  text-align: center;
  font-family: "Oswald", sans-serif;
  margin-top: 30px;
}

.title {
  color: #00612d;
  font-size: 1.3em;
  text-align: center;
  font-family: "Oswald", sans-serif;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .subasta-room-container {
    max-width: 85%;
    min-width: 85%;
    margin: 10px 10px;
  }
  .subasta-room-btn-container {
    width: 100%;
    font-size: 16px;
    height: 5px;
  }
  .subasta-room-type-title {
    font-size: 24px;
    margin-left: 0px;
  }
  .subasta-room-data-title {
    width: 30%;
    font-size: 16px;
  }
  .subasta-room-data-field {
    width: 70%;
    font-size: 14px;
  }
  .subasta-room-match-name {
    font-size: 16px;
    font-weight: 400;
    color: #00612d;
    text-align: center;
    padding-bottom: 10px;
  }
}
