.subasta-dashboard-container {
  position: relative;
  background-image: url("../../images/loginBg.jpg");
  background-size: contain;
  background-repeat: repeat-y;
  width: 100%;
}

.subasta-user-section {
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
  align-items: center;
}

.create-room-btn,
.search-room-btn {
  background-color: #ffa700;
  border: 3px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.create-room-btn img {
  width: 42px;
  height: 42px;
}
.search-room-btn img {
  width: 34px;
  height: 35px;
}

.subasta-user-card-container {
  display: block;
  margin: 0 auto;
  text-align: center;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
  background-color: white;
  border-radius: 13px;
  border-left: 14px solid #ffa700;
  width: 405px;
}

.subasta-user-card-first-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid #ffa700;
  height: 58px;
}

.subasta-user-card-text {
  font-size: 22px;
  font-weight: bold;
  color: #4a4a4a;
  line-height: 32px;
}

.subasta-user-ranking {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subasta-user-card-ranking-value {
  font-size: 26px;
  font-weight: bold;
  color: #ffa700;
}

.subasta-user-card-second-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 58px;
}

.subasta-key-icon-container {
  margin-left: 20px;
}

.subasta-user-card-key-ammount {
  font-size: 36px;
  font-weight: bold;
  color: #ffa700;
  line-height: 36px;
  margin-left: 10px;
}

.subasta-user-card-btn-container {
  display: flex;
  width: 130px;
  align-items: center;
  justify-content: space-around;
  background-color: #ffa700;
  border: 1px solid #788687;
  border-radius: 23px;
  font-size: 12px;
  height: 30px;
  padding: 0 15px;
  color: white;
  text-transform: uppercase;
  margin-left: 15px;
  margin-right: 15px;
  -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.26);
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.26);
}

.subasta-createRoomsMenu-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: scroll;
}

@media (max-width: 768px) {
  .subasta-user-section {
    width: 100%;
    margin-top: 20px;
  }
  .subasta-user-card-container {
    width: 80%;
    margin-bottom: 20px;
  }

  .subasta-user-card-text {
    font-size: 14px;
    font-weight: 400;
    line-height: initial;
    letter-spacing: 0;
  }

  /* .subasta-createRoomsMenu-container {
    position: fixed;
  } */
}
