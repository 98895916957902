.login-form {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.login-form label {
  display: block;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.login-form input[type="text"],
.login-form input[type="email"],
.login-form input[type="password"] {
  display: block;
  margin: 0 auto;
  width: 75%;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 97, 45, 0.4);
  border-radius: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #4a4a4a;
  margin-bottom: 10px;
  padding-left: 10px;
  line-height: 40px;
}

.login-form p {
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin: 10px 0;
}

.login-form a {
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}

.registerLink {
  color: #055a1c;
  font-size: 28px !important;
  font-family: "Oswald", sans-serif !important;
  font-weight: 600 !important;
}

.center-button {
  text-align: center;
  margin: 0 auto;
}

.subasta-separator-title {
  color: #9d9d9c;
  font-weight: 300;
  font-size: 16px;
  margin: 10px auto;
  text-align: center;
}
