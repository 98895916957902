.subasta-scoreOffering-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 300;
}

.subasta-scoreOffering-container {
  width: 40%;
  height: auto;
  background-color: white;
  border: 3px solid #00612d;
  border-radius: 16px;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
}

.subasta-scoreOffering-container h1 {
  font-family: "Oswald", sans-serif;
  font-weight: 900;
  font-size: 32px;
  color: #00612d;
  margin-top: 0;
  margin-bottom: 10px;
}

.subasta-scoreOffering-container h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  margin: 0 auto;
}

.subasta-scoreOffering-avatar-container img {
  width: 115px;
  margin-top: -120px;
  margin-left: 40px;
}

.subasta-scoreOffering-score-teams,
.subasta-scoreOffering-scores {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
}

.subasta-scoreOffering-score-teamName,
.subasta-scoreOffering-score {
  width: 100%;
}

.subasta-scoreOffering-score-teamName h2 {
  font-family: "Oswald", sans-serif;
  margin: 0 !important;
}

.subasta-scoreOffering-score h2 {
  margin: 0;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 3em;
  color: #00612d;
  line-height: 0.8em;
}

.subasta-scoreOffering-actualOffer {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #7d7d7d;
  margin: 10px auto 0px auto;
  padding: 5px 0;
  width: 80%;
  line-height: 1.3em;
}

.subasta-scoreOffering-actualOffer span {
  font-weight: 800;
  font-size: 16px;
  color: black;
}

.separator {
  width: 40%;
  margin: 0px auto;
  border-bottom: 1px solid #6e6e6e;
}

.subasta-scoreOffering-offer-form-container {
  width: 100%;
  margin: 0 auto;
  height: 50px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  align-items: center;
}

.subasta-scoreOffering-offer-button {
  width: 40px;
  height: 40px;
  border: 2px solid #00612d;
  background-color: #ffa700;
  border-radius: 50%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 48px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: 50px;
}

.subasta-scoreOffering-info {
  display: block;
  margin: 10px auto;
  font-size: 11px;
  font-style: italic;
  text-align: center;
  color: #7d7d7d;
}

.subasta-scoreOffering-offer-input {
  width: 60%;
  height: 50px;
  line-height: 50px;
  font-family: "Oswald", sans-serif;
  font-size: 28px;
  box-sizing: border-box;
  padding: 0px;
  text-align: center;
  border: none;
  border-bottom: 2px solid #00612d;
}

.subasta-scoreOffering-btn-container .btn-danger-small {
  width: 30px;
  position: relative;
  font-size: 18px;
  right: -15px;
  top: -15px;
  border: 2px solid #e94148;
  border-radius: 28px;
  height: 30px !important;
  float: right;
}

.subasta-scoreOffering-myOffer {
  color: #00612d;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 10px;
}

.subasta-scoreOffering-error {
  display: block;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  margin: 10px auto;
  padding: 0 5px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background-color: #e94148;
  color: white;
  border-radius: 15px;
}

.disabled {
  background-color: #6e6e6e;
  color: #eeeeee;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .subasta-scoreOffering-container {
    width: 80%;
    height: auto;
    margin-top: 60px;
  }

  .subasta-scoreOffering-avatar-container img {
    width: 30%;
    margin-top: -50px;
    margin-left: 40px;
  }

  .subasta-scoreOffering-btn-container .btn-danger-small {
    right: -5px;
    top: -15px;
  }
}
