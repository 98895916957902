body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  width: 55%;
  height: 50px;
  line-height: 50px;
  border-radius: 30px;
  cursor: pointer;
}

.btn img {
  width: 25px;
  height: 30px;
}

.btn-primary {
  background-color: #00612d;
  color: white !important;
  font-family: "Oswald", sans-serif !important;
  font-weight: bold !important;
  font-size: 30px;
}

.btn-danger {
  background-color: #e94148;
  color: white !important;
  font-family: "Oswald", sans-serif !important;
  font-weight: bold !important;
  font-size: 30px !important;
}

.btn-danger a {
  color: #ffffff;
  text-decoration: none;
}

.btn-danger-small a,
.btn-primary-small a {
  color: #ffffff;
  text-decoration: none;
}

.btn-primary-small {
  background-color: #00612d;
  color: white;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  height: 50px;
  line-height: 28px;
  padding: 7px 20px !important;
  text-decoration: none;
  cursor: pointer;
  height: 20px !important;
  line-height: 20px !important;
  text-align: center;
}

.btn-danger-small {
  background-color: #e94148;
  color: white;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  height: 50px;
  line-height: 28px;
  padding: 7px;
  text-decoration: none;
  cursor: pointer;
  height: 20px !important;
  line-height: 20px !important;
  text-align: center;
}

.btn-facebook {
  background-color: #4267b2;
  color: white;
  font-weight: bold;
  font-size: 32px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 10px;
  cursor: pointer;
  border-color: transparent;
  padding-bottom: 5px;
}

.btn-secondary {
  background-color: #ffffff !important;
  color: #055a1c !important;
  border-color: #055a1c;
  font-family: "Oswald", sans-serif !important;
  font-weight: bold !important;
  font-size: 31px !important;
  margin-bottom: 10px !important;
}

.btn-secondary a {
  color: #055a1c !important;
  text-align: center;
  text-decoration: none;
}

.centered {
  margin: 20px auto;
}

.mobile {
  display: none !important;
}
.desktop {
  display: flex !important;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: flex !important;
  }
  .btn {
    width: 80%;
    height: 40px;
    line-height: 40px;
    margin-top: 10px;
    padding: 0 10px;
  }
  .btn-primary,
  .btn-secondary,
  .btn-danger {
    font-size: 22px !important;
  }
  .btn-primary-small {
    width: 80%;
    margin: 0 auto !important;
    float: right;
    font-size: 14px;
  }

  .logo-container img {
    display: block;
    width: 70%;
    margin: 0 auto;
    text-align: center;
  }
}
