.login-container,
.signup-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
}

.img-desktop-container {
  width: 50%;
  height: 100%;
}

.img-desktop-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url("../../images/subastaHomeBg.jpg");
  background-size: cover;
}

.subasta-desktop-img-left-container {
  width: 100%;
}
.subasta-desktop-img-left-container img {
  width: 100%;
}

.login-form-container,
.signup-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  padding: 40px 0;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
  -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
  -o-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
  -ms-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
}

.subasta-login-subtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 21px;
  margin-bottom: 3px;
  margin-top: 10px;
}

.subasta-login-text {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #878787;
  font-style: italic;
  font-size: 16px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .img-desktop-container {
    display: none;
  }

  .login-form-container,
  .signup-form-container {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px;
    height: 100%;
    border-radius: 0;
    box-shadow: initial;
  }

  .signup-container {
    height: 100vh;
  }
}
