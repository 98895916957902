.signup-form {
  width: 90%;
}

.signup-header-container {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.signup-title {
  font-family: "Oswald", sans-serif;
  width: 100%;
  text-align: center;
}

.signup-btn-container {
  position: relative;
  top: 0;
  right: 0;
}

.signup-form label {
  display: block;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.signup-form input[type="text"],
.signup-form input[type="email"],
.signup-form input[type="password"],
.signup-form select {
  display: block;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 97, 45, 0.4);
  border-radius: 30px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #4a4a4a;
  margin-bottom: 10px;
  padding-left: 10px;
  line-height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.signup-form p {
  font-family: "Oswald", sans-serif;
  text-align: center;
  margin: 30px 0 10px 0;
}

.signup-form a {
  color: #4a4a4a;
}

.signup-password-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.signup-password-container-col50 {
  width: 90%;
  display: flex;
  justify-content: space-around;
}

.signup-password-container-col50 input[type="password"] {
  width: 97%;
  box-sizing: border-box;
  display: block;
  align-self: flex-end;
  font-size: 14px;
}

.signup-password-container-col50 label {
  width: 100%;
}

.signup-accept-terms {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
}

.signup-accept-terms p {
  width: 100%;
  margin: 0;
  text-align: center;
}

.recovery-pass {
  font-size: 18px !important;
}

@media (max-width: 768px) {
  .signup-btn-container {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}