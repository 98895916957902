.report-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4000;
  color: #333333;
  overflow: hidden;
}

.report-container {
  position: relative;
  width: 80%;
  height: 90%;
  background-color: white;
  padding: 20px;
  overflow: hidden;
  overflow-y: scroll;
}

.report-container h3 {
  color: #333333;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
}

.report-score {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px 15px;
  border-bottom: 1px solid #c5c5c5;
}

.report-heading-container,
.report-text-container {
  display: flex;
  width: 100%;
  color: #333333;
  margin-top: 15px;
}

.report-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.report-player-container {
  width: 100%;
}

.report-heading-container {
  border-bottom: 1px solid #c5c5c5;
}

.report-heading {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  width: 33%;
  text-align: left;
  font-size: 16px;
  color: #00612d;
}

.report-text {
  width: 33%;
  text-align: left;
  font-size: 14px;
  color: #333333;
}

.subtotal {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
  color: #00612d;
}
