.subasta-close-create-roomsIcon {
  margin: 257px 0 0 85px;
}

.subasta-create-rooms-menu {
  background-color: #ffffff;
  border: 2px solid #00612d;
  border-radius: 18px;
  margin-left: 105px;
  margin-top: -40px;
  width: 450px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.26);
}

.subasta-create-rooms-items-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.subasta-create-rooms-menu h1 {
  color: #00612d;
  text-align: center;
  margin-bottom: 0px;
  font-size: 24px;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}

.subasta-create-rooms-item {
  margin: 20px 5px;
  cursor: pointer;
}

.room-info {
  width: 100%;
  transition: ease-in-out all 300ms;
}

.room-info-separator {
  width: 60%;
  height: 1px;
  border: 1px solid #b9b9b9;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
}

.room-info-title {
  color: #00612d;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.room-info-description {
  width: 90%;
  display: block;
  margin: 0 auto;
}
.room-info-description p {
  text-align: justify;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 22px;
}

.room-info-btn-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  width: 90%;
  margin: 0 auto 20px auto;
}

.subasta-create-rooms-icon {
  text-align: center;
}

.iconDisabled {
  filter: grayscale(80);
}

.room-info-btn {
  font-size: 22px !important;
  width: 45% !important;
}

.room-info-description-item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0;
}

.room-info-description-image {
  display: block;
  width: 30%;
  margin-right: 20px;
}

.room-info-description-image img {
  width: 68px;
  text-align: center;
}

.subasta-create-rooms-btn-container {
  height: 10px;
}

.subasta-create-rooms-btn-container .btn-danger-small {
  width: 30px;
  position: absolute;
  font-size: 18px;
  right: 20px;
  border: 2px solid #e94148;
  border-radius: 28px;
  height: 30px !important;
}

@media (max-width: 768px) {
  .subasta-create-rooms-menu {
    display: block;
    position: absolute;
    text-align: center;
    margin: 0 auto !important;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-height: 90vh;
    overflow: scroll;
  }
}
